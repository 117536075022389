<template>
    <div class="salesManagement">
        <!-- <div class="searchArea">
            <el-input v-model="input" placeholder="请输入名称进行搜索..."></el-input>
            <el-input v-model="input" placeholder="请输入编码进行搜索..."></el-input>
            <el-date-picker
                v-model="timeSlot"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            >
            </el-date-picker>
            <el-button type="primary">
                搜索
            </el-button>
        </div> -->
        <div class="content">
            <div class="largeClass">
                <div class="cornerMarker">
                    <i>大类</i>
                </div>
                <div class="table">
                    <div
                        class="iconfont iconicon-up Selection"
                        :style="{top:selectlargeClassPosition}"
                        v-if="largeClassData && largeClassData.length > 0"
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div ref="content" @blur="preservationName($event)">
                                            {{ selectlargeClassData.name }}
                                        </div>
                                    </td>
                                    <td>{{ selectlargeClassData.code }}</td>
                                    <td>
                                        <button
                                            class="iconfont iconbianji modify"
                                            @click.stop="modify('largeClass')"
                                            v-if="readOnly"
                                        ></button>
                                        <button
                                            class="iconfont iconshanchu delete"
                                            @click="deleteClick('largeClass')"
                                            v-if="isDelete && readOnly"
                                        ></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table_header">
                        <table>
                            <thead>
                                <tr>
                                    <th>名称</th>
                                    <th>编号</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table_body" v-if="largeClassData && largeClassData.length > 0">
                        <table>
                            <tbody>
                                <template v-for="(item, index) in largeClassData">
                                    <tr
                                        :class="{active: largeClassActive == index}"
                                        :key="index"
                                        @click="selectLargeClass(item, index, $event)"
                                    >
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.code }}</td>
                                        <td>
                                            <button
                                                class="iconfont iconbianji modify"
                                                @click.stop="modify('largeClass', item)"
                                                v-if="readOnly"
                                            ></button>
                                            <button
                                                class="iconfont iconshanchu delete"
                                                @click.stop="deleteClick('largeClass', item, index)"
                                                v-if="isDelete && readOnly"
                                            ></button>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-if="readOnly">
                                    <td colspan="4" class="addTo">
                                        <button class="iconfont icontianjia1" @click="openAddTo('largeClass')">
                                            点击此处新增
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="noData" v-else>
                        <button v-if="!readOnly">
                            <span>暂无数据！</span>
                        </button>
                        <button v-else @click="openAddTo('largeClass')">
                            <i class="iconfont icontianjia1"></i>
                            <span>暂无大类，点击添加。</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="subClass">
                <div class="cornerMarker">
                    <i>子类</i>
                </div>
                <div class="table">
                    <div
                        class="iconfont iconicon-up Selection"
                        :style="{top:selectSubClassPosition}"
                        v-if="subClassData && subClassData.length > 0"
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <td>{{ selectSubClassData.name }}</td>
                                    <td>{{ selectSubClassData.code }}</td>
                                    <td>
                                        <button
                                            class="iconfont iconbianji modify"
                                            @click.stop="modify('subClass')"
                                            v-if="readOnly"
                                        ></button>
                                        <button
                                            class="iconfont iconshanchu delete"
                                            @click="deleteClick('subClass')"
                                            v-if="isDelete && readOnly"
                                        ></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table_header">
                        <table>
                            <thead>
                                <tr>
                                    <th>名称</th>
                                    <th>编号</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table_body" v-if="subClassData && subClassData.length > 0">
                        <table>
                            <tbody>
                                <template v-for="(item, index) in subClassData">
                                    <tr
                                        :class="{active: subClassActive == index}"
                                        :key="index"
                                        @click="selectSubClass(item, index, $event)"
                                    >
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.code }}</td>
                                        <td>
                                            <button
                                                class="iconfont iconbianji modify"
                                                @click.stop="modify('subClass', item)"
                                                v-if="readOnly"
                                            ></button>
                                            <button
                                                class="iconfont iconshanchu delete"
                                                @click.stop="deleteClick('subClass', item, index)"
                                                v-if="isDelete && readOnly"
                                            ></button>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-if="readOnly">
                                    <td colspan="4" class="addTo">
                                        <button class="iconfont icontianjia1" @click="openAddTo('subClass')">
                                            点击此处新增
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="noData" v-else>
                        <button v-if="!readOnly">
                            <span>暂无数据！</span>
                        </button>
                        <button v-else @click="openAddTo('subClass')">
                            <i class="iconfont icontianjia1"></i>
                            <span>暂无子类，点击添加。</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="subClasses">
                <div class="cornerMarker">
                    <i>小类</i>
                </div>
                <div class="table">
                    <div class="table_header">
                        <table>
                            <thead>
                                <tr>
                                    <th>名称</th>
                                    <th>编号</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table_body" v-if="subClassesData && subClassesData.length > 0">
                        <table>
                            <tbody>
                                <template>
                                    <tr :key="index" v-for="(item, index) in subClassesData">
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.code }}</td>
                                        <td>
                                            <button
                                                class="iconfont iconbianji modify"
                                                @click.stop="modify('subClasses', item)"
                                                v-if="readOnly"
                                            ></button>
                                            <button
                                                class="iconfont iconshanchu delete"
                                                @click.stop="deleteClick('subClasses', item, index)"
                                                v-if="isDelete && readOnly"
                                            ></button>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-if="readOnly">
                                    <td colspan="4" class="addTo">
                                        <button class="iconfont icontianjia1" @click="openAddTo('subClasses')">
                                            点击此处新增
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="noData" v-else>
                        <button v-if="!readOnly">
                            <span>暂无数据！</span>
                        </button>
                        <button v-else @click="openAddTo('subClasses')">
                            <i class="iconfont icontianjia1"></i>
                            <span>暂无小类，点击添加。</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            custom-class="newDictionaryDialog"
            :title="text.title"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
        >
            <el-form :model="form">
                <el-form-item :label="text.name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item :label="text.code">
                    <el-input v-model="form.code" :disabled="isModify"></el-input>
                    <p class="tips">
                        {{ text.tips }}
                    </p>
                </el-form-item>
                <el-form-item :label="text.sort">
                    <el-input v-model="form.sort" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input type="textarea" v-model="form.desc" placeholder="请输入说明内容..."></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">
                    取 消
                </el-button>
                <el-button type="primary" v-if="!isModify" @click="confirmAddTo">
                    确 定
                </el-button>
                <el-button type="primary" v-else @click="confirmModify">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    // 基础信息维护-销售管理
    name: 'sales-management',
    components: {},
    props: {},
    data() {
        return {
            input: '',
            timeSlot: '',
            // 是否有权限删除
            isDelete: true,
            // 默认父级编码为大类编码0
            parent_code: 0,
            // 字典类型
            type: '',
            // 大类数据
            largeClassData: [],
            // 大类当前选中下标
            largeClassActive: 0,
            // 大类当前选中数据
            selectlargeClassData: [],
            // 大类选中层位置
            selectlargeClassPosition: '0.6rem',
            // 大类编码提示信息
            largeClassTips: '编码大类是3位数字，包含2个部分，第1位是业务大分类，用1位数字表达,'
                            + '0基础管理￤1销售管理￤2采购管理￤3生产管理￤4物流运输管理￤5技术质量管理￤'
                            + '6账务管理￤7设备备件管理￤8固定资产管理；第2、3位是业务小分类，用2位数字表达，'
                            + '例如1销售管理中合同类型的大类编码为102。',

            // 子类数据
            subClassData: [],
            // 子类当前选中下标
            subClassActive: 0,
            // 子类当前选中数据
            selectSubClassData: [],
            // 子类选中层位置
            selectSubClassPosition: '0.6rem',
            // 子类编码提示信息
            subClassTips: '编码子类是3位大写字母，如果业务子类可以用2个汉语拼音大写首字母表达，'
                        + '则采用2位大写字母，第3位用数字0补齐；如果业务子类需要3个首字母，则采用3位大写字母；'
                        + '如果2个业务子类的前2个首字母相同，而且即使采用第3个首字母也无法区分，'
                        + '则第3位用数字顺序进行表达，如临时合同与零售合同，分别是102LS1、102LS2',

            // 小类数据
            subClassesData: [],
            // 小类编码提示信息
            subClassesTips: '编码小类是2位数字，按业务小类直接顺序编码；如果没有业务小类，则用“00”代替，以便所有编码位数相同。',

            // 弹出层状态
            dialogFormVisible: false,
            // 弹出层绑定字段
            form: {
                name: '',
                code: '',
                sort: 0,
                desc: '',
                tips: '',
            },
            // 弹出层文字
            text: {
                title: '',
                name: '',
                code: '',
                sort: '',
            },
            // 打开弹出层类别
            openClass: '',
            // 是否是修改标识
            isModify: false,
            // 当前修改数据
            modifyData: {},
            // 是否只读
            readOnly: false,
        };
    },
    watch: {
        $route(to) {
            // 重置参数
            this.resetParameters();
            // 是否只读
            this.isReadOnly();
            // 切换路由，获取大类数据
            this.gitClassData('largeClass', to);
        },
    },
    created() {
        // 是否只读
        this.isReadOnly();
        // 获取大类数据
        this.gitClassData('largeClass');
    },
    computed: {},
    methods: {
        // 是否只读
        isReadOnly() {
            const readOnly = this.$queryString(this.$route.meta.TableCode, 'readOnly');
            if (typeof readOnly !== 'undefined') {
                this.readOnly = readOnly;
            }
        },
        // 获取类型数据
        gitClassData(type, routerMeta) {
            if (routerMeta && routerMeta !== '') {
                // 字典类型赋值
                this.type = this.$queryString(routerMeta.meta.TableCode, 'type');
            } else {
                // 字典类型赋值
                this.type = this.$queryString(this.$route.meta.TableCode, 'type');
            }

            // 获取是否可删除权限
            // this.isDelete = this.$queryString(this.$route.meta.TableCode, 'deletePermission');
            this.$axios
                .get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=' + this.parent_code + '&type=' + this.type)
                .then(res => {
                    if (type === 'largeClass') {
                        this.largeClassData = [];
                        this.largeClassData = res;
                        // 子类数据
                        this.subClassData = [];
                        // 子类当前选中下标
                        this.subClassActive = 0;
                        // 子类当前选中数据
                        this.selectSubClassData = [];
                        // 小类数据
                        this.subClassesData = [];
                        if (this.largeClassData.length > 0) {
                            this.selectlargeClassData = this.largeClassData[0];
                            this.parent_code = this.largeClassData[0].code;
                            this.type = this.largeClassData[0].type;
                            // 获取子类数据
                            this.gitClassData('subClass');
                        }

                    } else if (type === 'subClass') {
                        this.subClassData = [];
                        this.subClassData = res;
                        // 小类数据
                        this.subClassesData = [];
                        if (this.subClassData.length > 0) {
                            this.selectSubClassData = this.subClassData[0];
                            this.parent_code = this.subClassData[0].code;
                            this.type = this.subClassData[0].type;
                            // 获取小类数据
                            this.gitClassData('subClasses');
                        }
                    } else if (type === 'subClasses') {
                        this.subClassesData = [];
                        this.subClassesData = res;
                    }
                    // 数据更新强制刷新页面
                    this.$forceUpdate();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择大类拉取子类数据
        selectLargeClass(data, index, event) {
            this.parent_code = data.code;
            this.type = data.type;
            this.selectlargeClassData = data;
            if (index) {
                this.largeClassActive = index;
            } else {
                this.largeClassActive = 0;
            }
            if (event) {
                this.selectlargeClassPosition = $(event.currentTarget).position().top + 'px';
            } else {
                this.selectlargeClassPosition = '0.6rem';
            }
            this.selectSubClassPosition = '0.6rem';
            // 获取子类数据
            this.gitClassData('subClass');
        },
        // 选择子类拉取小类数据
        selectSubClass(data, index, event) {
            this.parent_code = data.code;
            this.type = data.type;
            this.selectSubClassData = data;
            if (index) {
                this.subClassActive = index;
            } else {
                this.subClassActive = 0;
            }
            if (event) {
                this.selectSubClassPosition = $(event.currentTarget).position().top + 'px';
            } else {
                this.selectSubClassPosition = '0.6rem';
            }
            // 获取小类数据
            this.gitClassData('subClasses');
        },
        // 修改
        modify(type, data) {
            // 当前打开弹出层类别
            this.openClass = type;
            if (type === 'largeClass') {
                this.text = {
                    title: '修改大类',
                    name: '大类名称:',
                    code: '大类编号:',
                    sort: '排序编号',
                    tips: this.largeClassTips,
                };
            } else if (type === 'subClass') {
                this.text = {
                    title: '修改子类',
                    name: '子类名称:',
                    code: '子类编号:',
                    sort: '排序编号',
                    tips: this.subClassTips,
                };
            } else if (type === 'subClasses') {
                this.text = {
                    title: '修改小类',
                    name: '小类名称:',
                    code: '小类编号:',
                    sort: '排序编号',
                    tips: this.subClassesTips,
                };
            }
            if (!data) {
                if (type === 'largeClass') {
                    this.modifyData = this.selectlargeClassData;
                    this.form = {
                        name: this.selectlargeClassData.name,
                        code: this.selectlargeClassData.code,
                        desc: this.selectlargeClassData.remark,
                        sort: this.selectlargeClassData.sort_num,
                    };
                } else if (type === 'subClass') {
                    this.modifyData = this.selectSubClassData;
                    this.form = {
                        name: this.selectSubClassData.name,
                        code: this.selectSubClassData.code,
                        desc: this.selectSubClassData.remark,
                        sort: this.selectlargeClassData.sort_num,
                    };
                }
            } else {
                // 当前修改数据
                this.modifyData = data;
                this.form = {
                    name: data.name,
                    code: data.code,
                    desc: data.remark,
                    sort: data.sort_num,
                };
            }
            // 弹出修改层
            this.isModify = true;
            // 打开弹出层
            this.dialogFormVisible = true;
        },
        // 确认修改
        confirmModify() {
            this.$axios
                .put('/interfaceApi/baseinfo/dictionary_config/modify_code', {
                    code: this.form.code.toUpperCase(),
                    name: this.form.name,
                    remark: this.form.desc,
                    sort_num: this.form.sort,
                })
                .then(() => {
                    this.$message({
                        message: '修改成功！',
                        type: 'success',
                    });
                    this.modifyData.name = this.form.name;
                    this.modifyData.remark = this.form.desc;
                    this.dialogFormVisible = false;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 删除
        deleteClick(type, data, index) {
            let gitData;
            const _that = this;
            // 传参判断删除类型
            if (!data) {
                if (type === 'largeClass') {
                    gitData = this.selectlargeClassData.code;
                } else if (type === 'subClass') {
                    gitData = this.selectSubClassData.code;
                }
            } else {
                gitData = data.code;
            }

            this.$axios
                .delete('/interfaceApi/baseinfo/dictionary_config/delete/' + gitData, {
                })
                .then(() => {
                    _that.$message({
                        message: '删除成功！',
                        type: 'success',
                    });
                    if (index) {
                        if (type === 'largeClass') {
                            _that.largeClassData.splice(index, 1);
                        } else if (type === 'subClass') {
                            _that.subClassData.splice(index, 1);
                        } else if (type === 'subClasses') {
                            _that.subClassesData.splice(index, 1);
                        }
                    } else if (!index) {
                        if (type === 'largeClass') {
                            _that.largeClassData.splice(_that.largeClassActive, 1);
                            if (_that.largeClassData.length > 0) {
                                _that.selectlargeClassData = _that.largeClassData[0];
                                _that.selectLargeClass(_that.selectlargeClassData);
                            }
                        } else if (type === 'subClass') {
                            _that.subClassData.splice(_that.subClassActive, 1);
                            if (_that.subClassData.length > 0) {
                                _that.selectSubClassData = _that.subClassData[0];
                                _that.selectSubClass(_that.selectSubClassData);
                            }
                        }
                    }
                })
                .catch(error => {
                    _that.$message.error(error.ErrorCode.Message);
                });
        },
        // 新增弹出层打开
        openAddTo(type) {
            this.openClass = type;
            this.form = {
                name: '',
                code: '',
                desc: '',
                tips: '',
            };
            if (type === 'largeClass') {
                this.text = {
                    title: '新增大类',
                    name: '大类名称:',
                    code: '大类编号:',
                    sort: '排序编号',
                    tips: this.largeClassTips,
                };
            } else if (type === 'subClass') {
                this.text = {
                    title: '新增子类',
                    name: '子类名称:',
                    code: '子类编号:',
                    sort: '排序编号',
                    tips: this.subClassTips,
                };
            } else if (type === 'subClasses') {
                this.text = {
                    title: '新增小类',
                    name: '小类名称:',
                    code: '小类编号:',
                    sort: '排序编号',
                    tips: this.subClassesTips,
                };
            }
            // 打开新增层
            this.isModify = false;
            // 打开弹出层
            this.dialogFormVisible = true;
        },
        // 确认新增
        confirmAddTo() {
            let gitData;
            if (this.openClass === 'largeClass') {
                gitData = {
                    parent_code: '0',
                    parent_all_code: '0',
                    code: this.form.code.toUpperCase(),
                    name: this.form.name,
                    level: 0,
                    type: parseInt(this.type, 0),
                    sort_num: this.form.sort,
                };
            } else if (this.openClass === 'subClass') {
                if (this.selectlargeClassData !== '') {
                    gitData = {
                        parent_code: this.selectlargeClassData.code.toString(),
                        parent_all_code: '0',
                        code: this.form.code.toUpperCase(),
                        name: this.form.name,
                        level: 0,
                        type: parseInt(this.type, 0),
                        sort_num: this.form.sort,
                    };
                } else {
                    this.$message.error('请先选择大类！');
                }
            } else if (this.openClass === 'subClasses') {
                if (this.selectSubClassData !== '') {
                    gitData = {
                        parent_code: this.selectSubClassData.code.toString(),
                        parent_all_code: '0',
                        code: this.form.code.toUpperCase(),
                        name: this.form.name,
                        level: 0,
                        type: parseInt(this.type, 0),
                        sort_num: this.form.sort,
                    };
                } else {
                    this.$message.error('请先选择子类！');
                }
            }
            this.$axios
                .post('/interfaceApi/baseinfo/dictionary_config/create', gitData )
                .then(() => {
                    this.dialogFormVisible = false;
                    if (this.openClass === 'largeClass') {
                        this.largeClassData.push(gitData);
                        if (this.largeClassData.length === 1) {
                            this.selectlargeClassData = gitData;
                        }
                    } else if (this.openClass === 'subClass') {
                        this.subClassData.push(gitData);
                        if (this.subClassData.length === 1) {
                            this.selectSubClassData = gitData;
                        }
                    } else {
                        this.subClassesData.push(gitData);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 重置参数
        resetParameters() {
            // 是否只读
            this.readOnly = false;
            this.parent_code = 0;
            // 大类数据
            this.largeClassData = [];
            // 大类当前选中下标
            this.largeClassActive = 0;
            // 大类当前选中数据
            this.selectlargeClassData = [];
            // 大类选中层位置
            this.selectlargeClassPosition = '0.6rem';
            // 子类数据
            this.subClassData = [];
            // 子类当前选中下标
            this.subClassActive = 0;
            // 子类当前选中数据
            this.selectSubClassData = [];
            // 子类选中层位置
            this.selectSubClassPosition = '0.6rem';
            // 小类数据
            this.subClassesData = [];
        },
    },
    mounted() {},
};
</script>
<style lang="stylus">
.salesManagement
    background #fff !important
    padding 0.2rem
    .searchArea
        height 0.38rem
        margin-bottom 0.06rem
        .el-input,.el-date-editor,.el-button
            margin-right 0.2rem
        .el-input
            width 2rem
        .el-button
            padding 0 0.2rem
            line-height 0.36rem
            height 0.38rem
            padding 0
    .content
        height 100%
        position relative
        >div
            height 100%
            float left
            margin-right 0.2rem
            width calc(100%/3 - 0.14rem)
            background #fff
            position relative
            .cornerMarker
                width 0.4rem
                height 0.4rem
                position absolute
                top 0px
                left 4px
                z-index 2
                overflow hidden
                i
                    display inline-block
                    width 0.5rem
                    height 100%
                    background #5588F1
                    color #fff
                    font-size 0.12rem
                    line-height 0.6rem
                    text-align center
                    transform rotate(-45deg)
                    position absolute
                    top -0.15rem
                    left -0.2rem
            &:last-child
                margin-right 0
            .table
                height 100%
                border 1px solid #D7D7D7
                margin 0 4px
                position relative
                .Selection
                    background #fff
                    position absolute
                    height 0.63rem
                    width calc(100% + 6px)
                    border 3px solid #5588F1
                    top 0.6rem
                    left -3px
                    box-shadow 0 0 0.2rem 0.01rem rgba(0,0,0,0.4)
                    transition 0.3s
                    &:before
                        position absolute
                        right -0.22rem
                        top 0.12rem
                        font-size 0.3rem
                        color #5588F1
                    table
                        width 100%
                        height 100%
                        td
                            color #5588F1
                            height 0.56rem
                            line-height 0.56rem
                            &:last-child
                                background #5588F1
                                button
                                    color #fff
                        tr
                            border 3px solid #5588F1
                            border-top none
                            border-bottom none

                            td
                                color #5588F1
                                height 0.58rem
                                line-height 0.56rem
                                border none
                                &:last-child
                                    background #5588F1
                                    button
                                        color #fff
                .table_header
                    height 0.6rem
                .table_body
                    overflow hidden
                    height calc(100% - 0.6rem)
                    padding-bottom 1rem
                    overflow-y auto
                table
                    width 100%
                    border-collapse collapse
                    table-layout fixed
                    th,td
                        height 0.6rem
                        font-size 0.16rem
                        text-align center
                        &:nth-of-type(1)
                            width 50%
                        &:nth-of-type(2)
                            width 25%
                        &:nth-of-type(3)
                            width 25%
                    th
                        background #F0F0F0
                        color #022782
                    td
                        border-bottom 1px solid #D7D7D7
                        color #333333
                        white-space nowrap
                        overflow hidden
                        text-overflow ellipsis
                        vertical-align middle
                        &:nth-of-type(1)
                            padding 0 0.1rem
                        button
                            border none
                            background none
                            font-size 0.18rem
                            padding 0 0.1rem
                            height 0.56rem
                            cursor pointer
                            &.modify
                                color #FF6600
                            &.delete
                                color #EB5C67
                        div
                            width 100%
                            height 0.5rem
                            line-height 0.5rem
                        &.addTo

                            height 0.6rem
                            background #FCFCFC
                            border 1px dashed #D7D7D7
                            font-size 0.18rem
                            line-height 0.6rem
                            text-align center
                            margin 0.15rem
                            width calc(100% - 0.3rem)
                            cursor pointer
            &.largeClass
                position absolute
                top 0.14rem
                left 0
                z-index 1
                height calc(100% - 0.28rem)
            &.subClass
                position absolute
                top 0.14rem
                left calc(100%/3 + 0.06rem)
                z-index 2
                width 50%
                height calc(100% - 0.28rem)
                box-shadow 0 0 0.2rem 0.01rem rgba(0,0,0,0.4)
                .cornerMarker
                    left 3px
                .table
                    width 64.5%
                    margin-left 0
                    border-left 4px solid #5588F1
                    border-top none
                    border-bottom none
                    .Selection
                        width calc(100% + 10px)
                        left -6px
            &.subClasses
                position absolute
                top 0
                right 0
                z-index 3
                border 4px solid #5588F1
                box-shadow 0 0 0.2rem 0.01rem rgba(0,0,0,0.4)
                .cornerMarker
                    top -1px
                    left -1px
                .table
                    width 100%
                    margin 0
                    border none
            .noData
                text-align center
                margin-top 30%
                button
                    border none
                    background none
                    i
                        display inline-block
                        width 2.2rem
                        height 2.2rem
                        font-size 1rem
                        background #fcfcfc
                        color #d7d7d7
                        line-height 2.2rem
                        border 3px dashed #d7d7d7
                    span
                        display block
                        font-size 0.24rem
                        margin-top 0.3rem
                        color #979EA7
    .newDictionaryDialog
        width 7.6rem
        height auto
        .el-dialog__header
            padding 0
            font-size 0.18rem
            line-height 0.6rem
            background #6A7EBE
            text-align center
            .el-dialog__title,
            .el-icon
                color #fff
            .el-icon
                font-size 0.3rem
        .el-dialog__body
            padding 0.3rem 0.5rem 0
            .el-form-item
                .el-form-item__content
                    height auto
            .el-form-item__label
                font-size 0.16rem
                color #666666
                width 0.8rem
                text-align left
            .el-input
                width calc(100% - 0.9rem)
                input
                    text-transform:uppercase
            .el-textarea
                textarea
                    height 1.76rem
                    background #F9F9F9
                    border 1px solid #D7D7D7
                    resize none
            .tips
                font-size 0.12rem
                line-height 0.2rem
                color #979EA7
                padding-left 0.3rem
                margin-top 0.1rem
                position relative
                &:after
                    content:'!'
                    position absolute
                    left 0
                    top 0rem
                    width 0.18rem
                    height 0.18rem
                    background #EB656F
                    color #fff
                    border-radius 50%
                    font-size 0.12rem
                    line-height 0.18rem
                    text-align center
        .el-dialog__footer
            padding 0.1rem 0.5rem 0.2rem
</style>